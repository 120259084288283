import styled from 'styled-components';

const LinkStyles = comp => styled(comp)`
  margin-right: auto;
  width: max-content;
  text-decoration: none;
  color: ${props => props.color || props.theme.colors.claytonBlue};
  padding-right: 1.5rem;
  position: relative;
  font-weight: 400;
  font-size: ${props =>
    props.size === 'large'
      ? props.theme.type.button.large
      : props.theme.type.button.small};
  line-height: ${props => props.theme.lineHeights.button.small};

  svg {
    fill: currentColor;
    height: 1em;
    margin-top: -0.1em;
    margin-left: 0rem;
    transition: all 0.3s ease-in-out;
    position: absolute;
    bottom: 0.5em;
    right: 0;
    transform: translateY(45%);
  }

  &:hover,
  &:focus,
  &:active {
    svg {
      right: -0.5rem;
    }
  }
`;

export default LinkStyles;
